import request from '../axiosInterceptor'

const api = {
  getAllTasks(id: any) {
    return request({
      url: `${process.env.REACT_APP_API_BASEURL}v1/project-management/${id}/incidents/incidents-list`,
      method: 'get',
    })
  },
  getAllObservation(id: any) {
    return request({
      url: `${process.env.REACT_APP_API_BASEURL}v1/project-management/${id}/observations/observations-list`,
      method: 'get',
    })
  },
  getInspectionsSchedules(id: any) {
    return request({
      url: `${process.env.REACT_APP_API_BASEURL}v1/project-management/${id}/inspections/inspection-schedule-list`,
      method: 'get',
    })
  },
  getInspectionsScheduleById(id: any, scheduleId: string) {
    return request({
      url: `${process.env.REACT_APP_API_BASEURL}v1/project-management/${id}/inspections/inspection-schedule?schedule_id=${scheduleId}`,
      method: 'get',
    })
  },
  getInspections(id: any) {
    return request({
      url: `${process.env.REACT_APP_API_BASEURL}v1/project-management/${id}/inspections/list-inspection`,
      method: 'get',
    })
  },
  getNonConformance(id: any) {
    return request({
      url: `${process.env.REACT_APP_API_BASEURL}v1/project-management/${id}/inspections/non-conformance-list`,
      method: 'get',
    })
  },
  getInspectionTopic() {
    return request({
      url: `${process.env.REACT_APP_API_BASEURL}v1/master/inspection-topic`,
      method: 'get',
    })
  },
  getAllIncidents(id: any) {
    return request({
      url: `${process.env.REACT_APP_API_BASEURL}v1/project-management/${id}/incidents/incidents-list`,
      method: 'get',
    })
  },
  getIncidentDetails(pId: any, id: any) {
    return request({
      url: `${process.env.REACT_APP_API_BASEURL}v1/project-management/${pId}/incidents/incident-details-by-incident-id?incident_details_id=${id}`,
      method: 'get',
    })
  },
  getNonConformanceDetails(pId: any, id: any) {
    return request({
      url: `${process.env.REACT_APP_API_BASEURL}v1/project-management/${pId}/inspections/inspection-non-conformance?inspection_id=${id}`,
      method: 'get',
    })
  },
  getNonConformanceDetailsByNonConfId(pId: any, id: any) {
    return request({
      url: `${process.env.REACT_APP_API_BASEURL}v1/project-management/${pId}/inspections/non-conformance-details?nc_id=${id}`,
      method: 'get',
    })
  },
  getInspectionsDetails(pId: any, id: any) {
    return request({
      url: `${process.env.REACT_APP_API_BASEURL}v1/project-management/${pId}/inspections/details-inspection?inspection_id=${id}`,
      method: 'get',
    })
  },
  getObservationDetails(pId: any, id: any) {
    return request({
      url: `${process.env.REACT_APP_API_BASEURL}v1/project-management/${pId}/observations/observation-detail?observation_details_id=${id}`,
      method: 'get',
    })
  },
  getExportIncident(pId: any, format: string) {
    return request({
      url: `${process.env.REACT_APP_API_BASEURL}v1/project-management/${pId}/incidents/incidents-list-export?file_format=${format}`,
      method: 'get',
      responseType: format === 'xlsx' ? 'blob' : undefined,
    })
  },
  postHscHandle(data: any) {
    return request({
      url: `${process.env.REACT_APP_API_BASEURL}v1/project-management/${data?.pId}/observations/hse-status`,
      method: 'post',
      data: data,
    })
  },
  postIncidentHsc(data: any) {
    return request({
      url: `${process.env.REACT_APP_API_BASEURL}v1/project-management/${data?.pId}/incidents/hse-status`,
      method: 'post',
      data: data?.form,
    })
  },
  postNonConformanceHSE(pId: any, data: any) {
    return request({
      url: `${process.env.REACT_APP_API_BASEURL}v1/project-management/${pId}/inspections/non-conformance/hse-status`,
      method: 'post',
      data: data,
    })
  },
  postAddInspectionSchedule(id: any, data: any) {
    return request({
      url: `${process.env.REACT_APP_API_BASEURL}v1/project-management/${id}/inspections/add-inspection-schedule`,
      method: 'post',
      data: data,
    })
  },
  postEditInspectionSchedule(id: any, data: any) {
    return request({
      url: `${process.env.REACT_APP_API_BASEURL}v1/project-management/${id}/inspections/edit-inspection-schedule-details`,
      method: 'post',
      data: data,
    })
  },
  postUpdateNonConformancePopup(id: any, data: any) {
    return request({
      url: `${process.env.REACT_APP_API_BASEURL}v1/project-management/${id}/inspections/add-inspection-non-conformance`,
      method: 'post',
      data: data,
    })
  },
  getExportInspectionSchedule(id: any, format: any) {
    return request({
      url: `${process.env.REACT_APP_API_BASEURL}v1/project-management/${id}/inspections/schedule-list-export?file_format=${format}`,
      method: 'get',
      responseType: format === 'xlsx' ? 'blob' : undefined,
    })
  },
  getExportInspections(id: any, format: any) {
    return request({
      url: `${process.env.REACT_APP_API_BASEURL}v1/project-management/${id}/inspections/inspection-list-export?file_format=${format}`,
      method: 'get',
      responseType: format === 'xlsx' ? 'blob' : undefined,
    })
  },
  getExportInspectionsNonConformance(id: any, format: any) {
    return request({
      url: `${process.env.REACT_APP_API_BASEURL}v1/project-management/${id}/inspections/non-conformance-list-export?file_format=${format}`,
      method: 'get',
      responseType: format === 'xlsx' ? 'blob' : undefined,
    })
  },
  getExportRecentQueries(id: any, format: any, resourceName: any, resourceId: any) {
    return request({
      url: `${process.env.REACT_APP_API_BASEURL}v1/project-management/${id}/copilots/dashboard-recent-query-export?file_format=${format}&platform=${resourceName}&copilot_index=${resourceId}`,
      method: 'get',
      responseType: format === 'xlsx' ? 'blob' : undefined,
    })
  },
}

export default api
