/*
 *The context is to provide project-related information to the Project Management pages. It calls the permission API and saves data for all pages. If the data is lost on refresh, it automatically re-calls the project permission API to retrieve and store the latest data.
 */
import React, { createContext, useContext, useState, ReactNode } from 'react'

// Define the shape of your context
interface ProjectContextType {
  projectPermission: any
  setProjectPermission: any
}

// Create the context with a default value
const ProjectContext = createContext<ProjectContextType | undefined>(undefined)

// Create the provider component
const ProjectProvider = ({ children }: { children: ReactNode }) => {
  const [projectPermission, setProjectPermission] = useState<any>(null)
  return <ProjectContext.Provider value={{ projectPermission, setProjectPermission }}>{children}</ProjectContext.Provider>
}

const useProjectPermission = () => {
  const context = useContext(ProjectContext)
  if (context === undefined) {
    throw new Error('useProject must be used within a ProjectProvider')
  }
  return context
}

export { ProjectProvider, useProjectPermission }
